<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Métiers</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          placeholder=""
          debounce="0"
          @ionInput="recherche = $event.target.value"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ListeMetiers :metiers="metiersTrouves" />
      <!-- Créé par EMT -->
      <CreatedBy />
    </ion-content>
  </ion-page>
</template>
<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSearchbar
} from '@ionic/vue'

import ListeMetiers from '../components/ListeMetiers'
import CreatedBy from '@/components/CreatedBy'
import {mapGetters} from "vuex";

export default {
  name: 'PageMetiers',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSearchbar,
    CreatedBy,
    ListeMetiers
  },
  computed: {
    ...mapGetters(['metiersSansStandsInfo']),
    metiersTrouves () {
      if (this.recherche) {
        return this.metiersSansStandsInfo.filter((metier) => {
          return metier.nom.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
              .includes(this.recherche.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        })
      } else {
        return this.metiersSansStandsInfo
      }
    }
  },
  data () {
    return {
      recherche: ''
    }
  }
}
</script>

<style scoped>

</style>
